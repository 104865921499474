import { createMetrics, metricsConfig } from '@dx-ui/config-metrics';
import getConfig from 'next/config';

import { digitalData } from '../../config/metrics/digitalData';
import { ExtendedAdobeTagManager } from './ExtendedAdobeTagManager';

const { publicRuntimeConfig } = getConfig();

const config: { api: ExtendedAdobeTagManager } = createMetrics(
  metricsConfig({
    digitalData,
    debug: publicRuntimeConfig.TRACKING_DEBUG && /stg|test|local/.test(publicRuntimeConfig.APP_ENV),
    Interface: ExtendedAdobeTagManager,
    seedFile: publicRuntimeConfig.TRACKING_SEED_FILE,
    server: typeof window === 'undefined',
  })
);

export default config;
