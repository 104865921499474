import { useEffect, useState } from 'react';
import { isTranslatedUrl } from '@dx-ui/framework-i18n';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { buildFullUrl } from '../utils/routeHelpers';

export function useForwardUrl(forwardUrlProp?: string) {
  const { asPath, locale = 'en' } = useRouter();
  const forwardUrlPath = forwardUrlProp || `/${locale ?? 'en'}${asPath}`;
  const [forwardUrl, setForwardUrl] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    async function processForwardUrl() {
      const isHiltonHonorsHomePage = /\/hilton-honors\/$/.test(forwardUrlPath);
      const supportsInLanguageHiltonHonorsFlow = await isTranslatedUrl({
        locale,
        path: '/hilton-honors/guest/my-account/',
        rule: 'rule-ui-translate-guest',
        queryClient,
      });
      const hiltonHonorsLocale = supportsInLanguageHiltonHonorsFlow ? locale : 'en';
      const fullForwardUrl = buildFullUrl(
        isHiltonHonorsHomePage
          ? `/${hiltonHonorsLocale}/hilton-honors/guest/my-account/`
          : forwardUrlPath
      );

      setForwardUrl(fullForwardUrl);
    }

    void processForwardUrl();
  }, [forwardUrlPath, locale, queryClient]);

  return forwardUrl;
}
