import type { Link } from '@dx-ui/osc-link';

export const GlobalFooterLinks = (
  globalFooterLinks: { [key: string]: string },
  locale: string,
  handleClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => boolean
): Link[] => [
  {
    label: globalFooterLinks.webAccessibility,
    url: `/${locale}/p/accessibility`,
    isNewWindow: true,
    onClick: handleClick,
  },
  {
    label: globalFooterLinks.customerSupport,
    url: `https://help.hilton.com/s/`,
    isNewWindow: true,
    onClick: handleClick,
  },
  {
    label: globalFooterLinks.globalPrivacy,
    url: `/${locale}/p/global-privacy-statement/`,
    isNewWindow: true,
  },
  {
    isNewWindow: true,
    label: globalFooterLinks.careers,
    url: 'https://jobs.hilton.com/us/en',
    onClick: handleClick,
  },
  {
    isNewWindow: true,
    label: globalFooterLinks.development,
    url: 'https://www.hilton.com/en/corporate/development/',
    onClick: handleClick,
  },
  {
    isNewWindow: true,
    label: globalFooterLinks.media,
    url: 'https://stories.hilton.com/',
    onClick: handleClick,
  },
  {
    label: globalFooterLinks.usageAgreement,
    url: `/${locale}/p/site-usage-agreement/`,
    isNewWindow: true,
  },
  {
    label: globalFooterLinks.modernSlavery,
    url: 'https://ir.hilton.com/~/media/Files/H/Hilton-Worldwide-IR-V3/committee-composition/hilton-slavery-and-trafficking-statement-2019.pdf',
    onClick: handleClick,
    isNewWindow: true,
  },
  {
    label: globalFooterLinks.discountTermsAndConditions,
    url: `/${locale}/hilton-honors/honors-discount-terms/`,
    isNewWindow: true,
    onClick: handleClick,
  },
  {
    label: globalFooterLinks.termsAndConditions,
    url: `/${locale}/hilton-honors/terms/`,
    isNewWindow: true,
    onClick: handleClick,
  },
  {
    isNewWindow: true,
    label: globalFooterLinks.tourismMediator,
    url: 'https://www.hilton.com/en/p/tourism-and-travel/',
    onClick: handleClick,
  },
  {
    isNewWindow: true,
    label: globalFooterLinks.adChoices,
    url: 'http://optout.aboutads.info/?c=2&lang=EN#!%2F',
    onClick: handleClick,
  },
];
