import * as React from 'react';

import { Alert } from '../../components/Alert';
import Footer from '../../components/GlobalFooter/GlobalFooter';
import { Header } from '../../components/Header/Header';
import { OscMinimalHeader } from '../../components/Header/OscMinimalHeader';
import { SpecialAccountsFlowHeader } from '../../components/Header/SpecialAccountsFlowHeader';
import SearchWidget from '../../components/SearchForm/SearchForm';
import { defaultLayout } from '../../utils/constants/layouts';
import { useForwardUrl } from '../../hooks/useForwardUrl';
import { useRouter } from 'next/router';
import { useAuth } from '@dx-ui/framework-auth-provider';

type InheritedPageProps = {
  userAgent: string;
};

type LayoutProps = {
  hasAlert?: boolean;
  hasFixedHeader?: boolean;
  hasFooter?: boolean;
  hasGoHiltonHeader?: boolean;
  hasHeader?: boolean;
  hasSearchWidget?: boolean;
  isHomepage?: boolean;
  hasSpecialAccountsFlowHeader?: boolean;
  hasMinimalHeader?: boolean;
  isSMBOwner?: boolean;
  isSMBEmployee?: boolean;
};

export type Props = {
  children?: React.ReactNode;
  forwardUrl?: string;
  layout?: LayoutProps;
} & InheritedPageProps;

export const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  forwardUrl,
  children,
  layout = {},
}) => {
  const { guestInfo } = useAuth();
  const forwardPageURI = useForwardUrl(forwardUrl);
  const { pathname } = useRouter();
  const {
    isHomepage,
    hasAlert,
    hasHeader,
    hasGoHiltonHeader,
    hasFixedHeader,
    hasFooter,
    hasSearchWidget,
    hasSpecialAccountsFlowHeader,
    hasMinimalHeader,
    isSMBOwner,
    isSMBEmployee,
  } = { ...defaultLayout, ...layout };

  const getHeaderContent = React.useMemo(() => {
    if (!hasHeader) {
      return null;
    }

    if (hasMinimalHeader) {
      return <OscMinimalHeader />;
    }
    if (hasSpecialAccountsFlowHeader) {
      return (
        <SpecialAccountsFlowHeader
          currentPath={forwardUrl}
          forwardPageURI={forwardPageURI}
          isSMBOwner={isSMBOwner}
          isSMBEmployee={isSMBEmployee}
        />
      );
    }
    return (
      <Header
        isFixed={hasFixedHeader}
        isHomepage={isHomepage}
        forwardPageURI={forwardPageURI}
        hasGoHiltonHeader={hasGoHiltonHeader}
      />
    );
  }, [
    hasHeader,
    hasMinimalHeader,
    hasGoHiltonHeader,
    hasSpecialAccountsFlowHeader,
    hasFixedHeader,
    forwardUrl,
    isHomepage,
    forwardPageURI,
    isSMBOwner,
    isSMBEmployee,
  ]);

  return (
    <>
      {getHeaderContent}
      <main>
        {hasSearchWidget && (
          <SearchWidget
            searchFormDefaultValues={{
              hhonors: {
                ...guestInfo?.hhonors,
                isSMBMember: guestInfo?.hhonors?.isSMBMember || !!isSMBEmployee || !!isSMBOwner,
              },
              specialRates: {
                smbRate: pathname?.includes('business') && (!!isSMBEmployee || !!isSMBOwner),
              },
            }}
          />
        )}
        {hasAlert && <Alert />}
        <div>{children}</div>
      </main>
      {hasFooter && <Footer />}
    </>
  );
};
