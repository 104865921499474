import getConfig from 'next/config';
import { defaultPageData } from '../../config/metrics/digitalData';

const {
  publicRuntimeConfig: { APP_VER },
} = getConfig();
// If pages have some sort of dynamic URI piece (password recovery token), exclude the last part in the title
const lastPartExclusions: string[] = ['password-recovery'];

export const capitalize = (s: string) => s && s.charAt(0).toUpperCase() + s.slice(1);

type PageNameProps = Omit<
  Partial<typeof defaultPageData>,
  'pageCategory' | 'subSection' | 'subSubSection' | 'language'
> & {
  pageCategory?: string;
  subSection?: string | null;
  subSubSection?: string | null;
  language?: string;
};

// Browser:EN:HiltonHonors:<Category>:<pageName>
// Update 'guest' to 'account' to match GW
export const getPageName = (props: PageNameProps): string => {
  const language = props.language?.toUpperCase();
  const brandName = (props.brandName || defaultPageData.brandName).replace(' ', '');
  const pageName = props.pageName || defaultPageData.pageName;
  const category = props.pageCategory || props.subSection || defaultPageData.primaryCategory;
  const pageCategory = category === 'guest' ? 'account' : category;
  const primaryCategory = props.primaryCategory || defaultPageData.primaryCategory;
  const outputCategory =
    pageCategory === primaryCategory
      ? capitalize(pageCategory)
      : `${capitalize(primaryCategory)}:${capitalize(pageCategory)}`;
  return pageName === 'Confirmation'
    ? `Browser:${language}:${outputCategory}:${capitalize(pageName)}`
    : `Browser:${language}:${brandName}:${outputCategory}:${capitalize(pageName)}`;
};

type CmsPageNameProps = {
  language?: string;
  brandName?: string;
  primaryCategory?: string;
  pageType?: string;
  pageDetail?: string[];
};

export const getCmsPageName = (props: CmsPageNameProps) => {
  const language = props.language?.toUpperCase();
  const brandName = `${props.brandName}`.replace(' ', '');
  let pageName = `Browser:${language}:${brandName}:${props.primaryCategory}:${props.pageType}`;

  const pageDetails = props.pageDetail?.filter((detail) => !!detail);

  if (Array.isArray(pageDetails) && pageDetails.length) {
    pageName += `:${pageDetails.join(':')}`;
  }

  return pageName;
};

// Since pathname is based on the route definition,
// remove duplicates and ensure uri part is truthy
export const getUriParts = (pathname: string): string[] => {
  if (pathname.length > 1) {
    return pathname.split('/').reduce((uriParts: string[], part: string) => {
      const parsedPart = part.split(/[?#]/)[0];
      if (!uriParts.includes(parsedPart) && Boolean(parsedPart)) {
        uriParts.push(parsedPart);
      }
      return uriParts;
    }, []);
  }

  return [pathname];
};

export const getPageTitle = (pathname: string): string => {
  const parts = getUriParts(pathname);

  const index = lastPartExclusions.find((uriPart) => parts.includes(uriPart)) ? 2 : 1;
  const last = (parts && parts[parts.length - index]).replace(/[[\].]/g, '');

  if (last) {
    if (last === 'hilton-honors') return 'Home';
    return humanizePageTitle(last);
  }
  return '';
};

// Get page title based on dash case: my-honors-card => My Honors Card
export const humanizePageTitle = (title: string): string =>
  title.split('-').map(capitalize).join(' ');

export const formatLabel = (label: string): string => label.replace(/ /g, '').toLowerCase();

export const getAppVersion = () => {
  const parts = APP_VER.split('/');
  return parts[parts.length - 1];
};
