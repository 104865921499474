import { useAuth } from '@dx-ui/framework-auth-provider';
import { useLocation } from '@dx-ui/framework-location-provider';
import {
  ShopFormDates,
  ShopForm,
  ShopFormLocation,
  ShopFormRooms,
  ShopFormSpecialRates,
} from '@dx-ui/osc-shop-form';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { useLanguage, useTranslation } from '../../hooks';
import { Goals } from '../../types/Conductrics';
import { sendConductricsReward } from '../../utils/helpers/conductrics';

import type { FormDefaultValues } from '@dx-ui/osc-shop-form';
import { useState } from 'react';
import { Status, sendReward, useConductricsSelection } from '@dx-ui/framework-conductrics';
import { useConductrics } from '../../hooks/useConductrics';
import { useIsClient } from 'usehooks-ts';

type ShopFormSubmitHandler = React.ComponentProps<typeof ShopForm>['onSubmit'];
type SearchFormData = Parameters<NonNullable<ShopFormSubmitHandler>>[0];

type FormSubmitProps = {
  language: string;
  formData: SearchFormData;
  route?: string;
  isUrlFromSpeedbump?: boolean;
  redirectUrl?: string;
};

type InputProps = {
  searchFormDefaultValues?: Partial<FormDefaultValues>;
};

const {
  publicRuntimeConfig: { REACT_APP_BRAND_HOST },
} = getConfig();

const onFormSubmit = ({
  language,
  route,
  isUrlFromSpeedbump = false,
  redirectUrl,
}: FormSubmitProps) => {
  if (
    route === '/en/hilton-honors/guest/my-account/' ||
    route === '/en/hilton-honors/credit-cards/'
  ) {
    sendConductricsReward(Goals.FindAHotel);
  }

  let url = redirectUrl;
  if (redirectUrl) {
    if (isUrlFromSpeedbump) url = redirectUrl?.replace(`/${language}/`, `/en/`);
    url = `https://${REACT_APP_BRAND_HOST}${url}`;

    if (url) window.location.assign(url);
  }
};

const SearchWidget = ({ searchFormDefaultValues }: InputProps) => {
  const { t } = useTranslation('osc-rooms');
  const { coordinate } = useLocation();
  const language = useLanguage();
  const { guestId, guestInfo } = useAuth();
  const router = useRouter();
  const isClient = useIsClient();

  //NHCSEARCH-5088 Remove Flexible dates when reset date is present
  useConductrics();
  const REMOVE_FLEXIBLE_DATES_AGENT_ID = 'a-88XVLKrnNI';
  const MY_FLEXIBLE_DATES_CLICK_GOAL = 'g-lNDlYVzRu9';
  const RESET_DATES = 'g-gjmxUjWSsN';
  const [enableRemoveFlexibleDatesTest, setEnableRemoveFlexibleDatesTest] = useState(false);
  const { isLoaded: isRemoveFlexibleDatesTestLoaded, selection: removeFlexibleDatesSelection } =
    useConductricsSelection(
      REMOVE_FLEXIBLE_DATES_AGENT_ID,
      enableRemoveFlexibleDatesTest ? Status.OK : Status.PENDING
    );

  const isRemoveFlexibleDatesDefault =
    isRemoveFlexibleDatesTestLoaded && removeFlexibleDatesSelection?.choice?.toLowerCase() === 'a';
  const isRemoveFlexibleDatesVariant =
    isRemoveFlexibleDatesTestLoaded && removeFlexibleDatesSelection?.choice?.toLowerCase() === 'b';

  const searchDefaultValues: FormDefaultValues = {
    brandCode: 'HH',
    hhonors: guestInfo?.hhonors,
  };

  const handleFormSubmit: ShopFormSubmitHandler = (formData) => {
    const redirectUrl = formData?.url ?? '';
    onFormSubmit({
      language,
      formData,
      route: `/${router?.locale ?? 'en'}${router?.asPath}`,
      redirectUrl,
    });
  };

  const searchFormKey = isClient
    ? window.btoa(encodeURI(JSON.stringify(searchFormDefaultValues || searchDefaultValues)))
    : 'search-form';

  return (
    <div className="w-full py-4 lg:mb-6">
      <ShopForm
        onSubmit={handleFormSubmit}
        onChange={() => sendConductricsReward(Goals.EditSearchWidget)}
        key={searchFormKey}
        targetOHWPage="search"
        defaultValues={searchFormDefaultValues || searchDefaultValues}
        language={language}
        enableDatesFlexDreamRouting={true}
        shouldDisplayGeocodeErrorModal={true}
        hasErrorBanner={false}
      >
        <ShopFormLocation
          coordinate={coordinate}
          required={true}
          sessionId={(guestId && guestId.toString()) || 'dx-guests-ui'}
          autocompleteUri={`https://${REACT_APP_BRAND_HOST}/dx-customer/autocomplete`}
          language={language}
        />
        <ShopFormDates
          language={language}
          hasTodayAsDefault={false}
          onOpen={() => {
            //NHCSEARCH-5088
            if (isRemoveFlexibleDatesTestLoaded) setEnableRemoveFlexibleDatesTest(true);
          }}
          {...(isRemoveFlexibleDatesVariant ? { hideFlexDates: true } : {})} //NHCSEARCH-5088
          onFlexibleDates={() => {
            //NHCSEARCH-5088
            if (isRemoveFlexibleDatesDefault) sendReward(MY_FLEXIBLE_DATES_CLICK_GOAL);
          }}
          onResetDates={() => {
            //NHCSEARCH-5088
            if (isRemoveFlexibleDatesDefault || isRemoveFlexibleDatesVariant)
              sendReward(RESET_DATES);
          }}
        />
        <ShopFormRooms
          ageRange={{ min: 0, max: 18 }}
          occupancyLimitMessage={t('occupancy.occupancyLimitMessage')}
        />
        <ShopFormSpecialRates />
      </ShopForm>
    </div>
  );
};

export default SearchWidget;
