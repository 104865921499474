import { useMemo } from 'react';
import Cookies from 'universal-cookie';

import { SEGMENTATION_COOKIE_NAME, SEGMENTATION_COOKIE_NAME_DEV } from '../utils/constants/rules';
import getConfig from 'next/config';
import { useIsClient } from 'usehooks-ts';

const cookies = new Cookies();

// for lower environments check if we have a dev cookie & use if it exists
const getSegmentCookie = (cookies: Cookies) => {
  const {
    publicRuntimeConfig: { APP_ENV },
  } = getConfig();
  let cookie = '';

  if (APP_ENV !== 'prd') {
    cookie = cookies?.get(SEGMENTATION_COOKIE_NAME_DEV);
  }
  if (!cookie) {
    cookie = cookies?.get(SEGMENTATION_COOKIE_NAME);
    cookies.set(SEGMENTATION_COOKIE_NAME, cookie, {
      domain: '.hilton.com',
      path: '/',
      sameSite: 'lax',
      secure: true,
      encode: (value) => value,
    });
  }
  return cookie || '';
};

export const useSegments = () => {
  const isClient = useIsClient();
  return useMemo(() => {
    const tms: string = isClient ? getSegmentCookie(cookies) : '';
    const segments = tms
      ?.split(',')
      .filter((entry) => (entry.toLowerCase() || '').startsWith('web'))
      .map((entry) => entry.split('=')[1] || '');
    return segments;
  }, [isClient]);
};
