import type { Rule } from '@dx-ui/utilities-url-config';
import { RuleEngine } from '@dx-ui/utilities-url-config';
import { useEffect, useState } from 'react';

import { useFeatureConfigsQuery } from '../queries/generated/react-query';

export const useGetRuleEngine = () => {
  const [ruleEngine, setRuleEngine] = useState<RuleEngine>();

  const { data, isPending } = useFeatureConfigsQuery({ names: ['rule-ui-translate-urls'] });

  useEffect(() => {
    if (!isPending && data) {
      const conditions = data?.featureConfigs[0];
      setRuleEngine(new RuleEngine(conditions as Rule));
    }
  }, [data, isPending]);

  return {
    ruleEngine,
  };
};
