import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import * as React from 'react';

import { getGuestFirstName, useAuth } from '@dx-ui/framework-auth-provider';
import { Header as OSCHeader } from '@dx-ui/osc-header';
import { Speedbump } from '@dx-ui/osc-speedbump';

import { useGetRuleEngine } from '../../hooks/useGetRuleEngine';
import { getExternalUrl, getLoginRedirectUrl } from '../../utils/helpers/urlBuilder';
import { getTranslateRuleUrl } from '../../utils/routeHelpers';

import type { Facts } from '@dx-shared/rule-engine';
import type { HeaderProps } from '@dx-ui/osc-header';

import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { LoginResponse } from '@dx-ui/osc-login';
import type { TFunction } from 'i18next';
import { useIsClient } from 'usehooks-ts';
import { useSmb_MemberIdAndStatusQuery } from '../../queries/generated/react-query';
import { useLanguage } from '../../hooks';

type Props = {
  isHomepage?: boolean;
  isFixed?: boolean;
  forwardPageURI?: string | null;
  hasGoHiltonHeader?: boolean;
};

type GetLinkProps = {
  isAuthenticated: boolean;
  isTMHOrOwner: boolean;
  locale: string | undefined;
};

const {
  publicRuntimeConfig: {
    REACT_APP_BRAND_HOST,
    TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK,
    EXTERNAL_TMTP_PROGRAM_DETAILS_URL,
  },
} = getConfig();

export const Header = (props: Props) => {
  const [user, setUser] = React.useState<HeaderProps['user']>();
  const isClient = useIsClient();
  const { locale, asPath, query } = useRouter();
  const { forwardPageURI, hasGoHiltonHeader } = props;
  const [t] = useTranslation('header');
  const { isAuthenticated, logout, login, guestInfo, guestId } = useAuth();
  const language = useLanguage();
  const { ruleEngine } = useGetRuleEngine();
  const [showSpeedbumpForModal, setShowSpeedbumpForModal] = React.useState<boolean>(false);
  const [speedbumpContinueUrl, setSpeedbumpContinueUrl] = React.useState<string>();

  const getCreditCardUrl = (locale: string) => {
    switch (locale) {
      case 'ja':
        return 'https://ac.ebis.ne.jp/tr_set.php?argument=i5Y4SXmk&ai=202111hm002&agentid=881501303';
      case 'de':
        return 'https://www.hiltonhonorscreditcard.de/';
      default:
        return `/${locale}/hilton-honors/credit-cards/?cid=OH,HH,CobrandHonorsNav,MULTIPR,Tile,Home,SingleLink,i81096`;
    }
  };
  const MEMBER_NAV_LINKS = [
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('locations'),
      onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => handleShowSpeedbumpForHeaderLinks(e),
      url: getExternalUrl(
        `/${locale}/locations/?cid=OH,HH,HonorsNav,MULTIPR,Tile,Home,SingleLink,i80949`
      ),
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('activity'),
      onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => handleShowSpeedbumpForHeaderLinks(e),
      url: `/${locale}/hilton-honors/guest/activity/`,
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('creditCards'),
      url: getCreditCardUrl(locale as string),
      ...(['ja', 'de'].includes(locale as string)
        ? {}
        : {
            onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) =>
              handleShowSpeedbumpForHeaderLinks(e),
          }),
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('points'),
      onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => handleShowSpeedbumpForHeaderLinks(e),
      url: `/${locale}/hilton-honors/guest/points/`,
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('profile'),
      onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => handleShowSpeedbumpForHeaderLinks(e),
      url: `/${locale}/hilton-honors/guest/profile/`,
    },
  ];
  const defaultLinks = [
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('benefits'),
      url: `/${locale}/hilton-honors/member-benefits/`,
    },
    {
      adaDescription: t('points'),
      isNewWindow: false,
      label: t('points'),
      url: `/${locale}/hilton-honors/points/`,
    },
    {
      adaDescription: t('offers'),
      isNewWindow: false,
      label: t('offers'),
      onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) => handleShowSpeedbumpForHeaderLinks(e),
      url: `https://${REACT_APP_BRAND_HOST}/en/offers/`,
    },
    {
      adaDescription: '',
      isNewWindow: false,
      label: t('creditCards'),
      url: getCreditCardUrl(locale as string),
      ...(['ja', 'de'].includes(locale as string)
        ? {}
        : {
            onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) =>
              handleShowSpeedbumpForHeaderLinks(e),
          }),
    },
    {
      adaDescription: t('findStay'),
      isNewWindow: false,
      label: t('findStay'),
      url: getExternalUrl(`/${locale}/book/reservation/find/`),
    },
  ];
  const links = isAuthenticated ? MEMBER_NAV_LINKS : defaultLinks;

  const brandLink =
    isClient && isAuthenticated
      ? `/${locale}/hilton-honors/guest/my-account/`
      : `/${locale}/hilton-honors/`;

  const { data } = useSmb_MemberIdAndStatusQuery(
    { guestId: guestId as number, language },
    { enabled: !!guestId }
  );

  const isSMBMember = data?.programAccounts && data?.programAccounts?.length > 0;

  React.useEffect(() => {
    setUser(
      guestInfo
        ? {
            name: getGuestFirstName({ guestInfo }),
            honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
            honorsPoints: guestInfo?.hhonors?.summary?.totalPoints || 0,
            honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
          }
        : undefined
    );
  }, [guestInfo]);

  const handleSpeedbumpContinue = React.useCallback(() => {
    setShowSpeedbumpForModal(false);
    speedbumpContinueUrl && window.location.assign(speedbumpContinueUrl);
  }, [speedbumpContinueUrl]);

  const handleShowSpeedbumpForHeaderLinks = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { currentTarget } = e;
    const href = currentTarget?.href || (currentTarget?.parentElement as HTMLAnchorElement)?.href;
    const ruleUrl = getTranslateRuleUrl(new URL(href));

    if (locale !== 'en' && !ruleEngine?.run({ language: locale, url: ruleUrl } as Facts)) {
      e.preventDefault();
      e.stopPropagation();

      const enUri = !href?.includes('/en/') ? href?.replace(`/${locale}/`, `/en/`) : href;
      setSpeedbumpContinueUrl(enUri);
      setShowSpeedbumpForModal(true);
      return;
    }
  };

  const onSignInAttempt = React.useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
        if (locale !== 'en') {
          const ruleUrl = forwardPageURI && getTranslateRuleUrl(new URL(forwardPageURI));
          if (ruleUrl && !ruleEngine?.run({ language: locale, url: ruleUrl } as Facts)) {
            setSpeedbumpContinueUrl(forwardPageURI);
            setShowSpeedbumpForModal(true);
            return;
          }
        }
        forwardPageURI && window.location.assign(forwardPageURI);
      }
    },
    [login, locale, forwardPageURI, ruleEngine]
  );

  const onSignOut = React.useCallback(async () => {
    await logout().then(() => {
      if (asPath.includes('hgv-max')) {
        const hgvRedirectUrl = `/${locale ?? 'en'}/hgv-max/`;
        window.location.assign(hgvRedirectUrl);
      }

      if (asPath.includes('go-hilton')) {
        const goHiltonRedirectUrl = `/${locale ?? 'en'}/go-hilton/`;
        window.location.assign(goHiltonRedirectUrl);
      }

      if (asPath.includes('business')) {
        const smbLoginRedirectUrl = `/${locale ?? 'en'}/business/login/`;
        window.location.assign(smbLoginRedirectUrl);
      }

      if (/hilton-honors\/guest/.test(asPath)) {
        const redirectUrl = /guest\/points/.test(asPath)
          ? `/${locale ?? 'en'}/hilton-honors/points/`
          : getLoginRedirectUrl(asPath, query, locale as SupportedLanguage);
        window.location.assign(redirectUrl);
      }
    });
  }, [asPath, locale, logout, query]);

  const getGoHiltonLinks = (
    t: TFunction<'header'>,
    { isAuthenticated, isTMHOrOwner, locale }: GetLinkProps
  ) => {
    const hotelSearch = {
      adaDescription: '',
      isNewWindow: false,
      label: t('hotelSearch'),
      url: `/${locale}/go-hilton/find-hotels/`,
    };
    const findStay = {
      adaDescription: '',
      isNewWindow: false,
      label: t('findStay'),
      url: `/${locale}/book/reservation/find/`,
    };
    const findStayReservations = {
      ...findStay,
      url: `/${locale}/hilton-honors/guest/my-account/`,
    };
    const programGuide = {
      adaDescription: '',
      isNewWindow: false,
      label: t('programDetails'),
      url: `${EXTERNAL_TMTP_PROGRAM_DETAILS_URL}`,
    };
    const existingReservations = {
      adaDescription: '',
      isNewWindow: false,
      label: t('existingRes'),
      url: `/${locale}/hilton-honors/guest/activity/`,
    };
    const manageFAndF = {
      adaDescription: '',
      isNewWindow: false,
      label: t('manageFAndF'),
      url: `${TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK}`,
    };

    if (!isAuthenticated) {
      return [programGuide, findStay];
    }

    if (isAuthenticated && isTMHOrOwner) {
      return [hotelSearch, programGuide, manageFAndF, existingReservations];
    }
    return [hotelSearch, programGuide, findStayReservations];
  };

  const goHiltonLinks = React.useMemo(
    () =>
      getGoHiltonLinks(t, {
        isAuthenticated,
        isTMHOrOwner: Boolean(guestInfo?.hhonors?.isOwner || guestInfo?.hhonors?.isTeamMember),
        locale,
      }),
    [isAuthenticated, guestInfo, locale, t]
  );

  const headerLinks =
    hasGoHiltonHeader && isClient ? goHiltonLinks : isClient ? links : defaultLinks;
  const additionalLink = isSMBMember
    ? [
        {
          label: t('memberBenefits'),
          url: `/${locale}/hilton-honors/member-benefits/`,
        },
        {
          label: 'Manage Hilton for Business',
          url: `/${locale}/business/manage/`,
        },
      ]
    : [
        {
          label: t('memberBenefits'),
          url: `/${locale}/hilton-honors/member-benefits/`,
        },
      ];

  return (
    <>
      <OSCHeader
        brand={{
          url: brandLink,
          name: t('hiltonHonors'),
          code: 'HH',
          onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) =>
            handleShowSpeedbumpForHeaderLinks(e),
        }}
        loginOptions={{
          options: {
            showCreatePassword: true,
            forgetInfoLink: {
              url: `https://${REACT_APP_BRAND_HOST}/${locale}/hilton-honors/login/forgot-information/`,
            },
            secureInfoLink: {
              url: `https://${REACT_APP_BRAND_HOST}/${locale}/p/global-privacy-statement/`,
            },
            joinLink: {
              url: `https://${REACT_APP_BRAND_HOST}/${locale}/hilton-honors/join/?ocode=JHTNW`,
            },
          },
        }}
        isFluid={true}
        mainNavLinks={headerLinks}
        onSignOut={onSignOut}
        onSignInAttempt={onSignInAttempt}
        user={user}
        userLinks={{
          accountLink: {
            url: `/${locale}/hilton-honors/guest/my-account/`,
            onClick: (e: React.SyntheticEvent<HTMLAnchorElement>) =>
              handleShowSpeedbumpForHeaderLinks(e),
          },
          signInLink: { url: `https://${REACT_APP_BRAND_HOST}/${locale}/auth2/guest/login/` },
          signUpLink: {
            url: `https://${REACT_APP_BRAND_HOST}/${locale}/hilton-honors/join/?ocode=JHTNW`,
          },
          additionalLinks: additionalLink,
        }}
      />
      <Speedbump
        isShowing={showSpeedbumpForModal}
        onClose={() => setShowSpeedbumpForModal(false)}
        onContinue={handleSpeedbumpContinue}
      />
    </>
  );
};
