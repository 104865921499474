import { AuthClient } from '@dx-ui/framework-auth-provider';
import { isBrowser } from './helper';
import type { IncomingMessage } from 'http';
import getConfig from 'next/config';

/**
 * 30 min value before user is logged out.
 */
export const IDLE_TIMEOUT = 30 * 60000;

export const auth = (
  req?: Request,
  initialAuthState?: ConstructorParameters<typeof AuthClient>[0]['restore']
) => {
  const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
  return new AuthClient({
    appName: publicRuntimeConfig.APP_NAME,
    authEndpoint: isBrowser
      ? publicRuntimeConfig.DX_AUTH_API_CLIENT_URI
      : serverRuntimeConfig.DX_AUTH_API_SERVER_URI,
    gqlEndpoint: isBrowser
      ? publicRuntimeConfig.DX_GRAPHQL_CLIENT_URI
      : serverRuntimeConfig.DX_GRAPHQL_SERVER_URI,
    appId: publicRuntimeConfig.DX_AUTH_APP_CUSTOMER_ID,
    request: req as unknown as IncomingMessage,
    restore: initialAuthState,
  });
};

export const callReservationLogin = async ({ confNumber, lastName, arrivalDate }) => {
  if (!isBrowser) throw Error('Reservation login must only be called client-side');

  const { publicRuntimeConfig } = getConfig();

  const options = {
    method: 'POST',
    credentials: 'same-origin' as RequestCredentials,
    headers: {
      'content-language': 'en',
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      app_id: publicRuntimeConfig.DX_AUTH_APP_CUSTOMER_ID,
      confNumber,
      lastName,
      arrivalDate,
    }),
  };

  let result: Response | undefined;
  try {
    result = await window.fetch(publicRuntimeConfig.DX_AUTH_API_RES_LOGIN, options);
    if (!result.ok) {
      throw Error(result.statusText);
    } else {
      const data = await result.json();
      return data;
    }
  } catch (error) {
    throw Error(error as string);
  }
};
