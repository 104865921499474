import { AdobeTagManager } from '@dx-ui/config-metrics';

import {
  capitalize,
  formatLabel,
  getAppVersion,
  getPageName,
  getCmsPageName,
} from '../../utils/helpers/metrics';

import { defaultPageData } from './digitalData';

type EventAction = {
  eventAction: string;
  eventIdx?: number;
};

type PageDataProps = {
  brandCode?: string;
  brandName?: string;
  language?: string;
  primaryCategory?: string;
  pageName?: string;
  pageType?: string;
  pageDetail?: string[];
  subSection?: string | null;
  subSubSection?: string | null;
  siteType?: string;
  expType?: string;
  exp?: string;
  isCmsPage?: boolean;
  loginStatus?: string;
};

export class ExtendedAdobeTagManager extends AdobeTagManager {
  public setDefaultPageData(props: PageDataProps[]) {
    const mergedProps: PageDataProps = {
      ...defaultPageData,
      ...props,
    };
    const {
      language,
      brandName,
      brandCode,
      expType,
      exp,
      primaryCategory,
      pageType,
      subSubSection,
      siteType,
      pageDetail,
      isCmsPage,
      loginStatus,
    } = mergedProps;
    let { subSection } = mergedProps;

    // Update 'guest' to 'account' to match GW
    if (subSection === 'guest') {
      subSection = 'account';
    }

    this._set('page.pageInfo.language', language);
    this._set('page.category.brand', brandCode);
    this._set('page.category.exp', exp);
    this._set('page.category.siteName', brandName);
    this._set('page.attributes.expType', expType);
    this._set('page.pageInfo.pageTitle', document.title);
    this._set('page.attributes.version', `${getAppVersion()}`);

    pageType && this._set('page.pageInfo.pageType', pageType);
    this._set('page.pageInfo.pageDetail1', (pageDetail && pageDetail[0]) || '');
    this._set('page.pageInfo.pageDetail2', (pageDetail && pageDetail[1]) || '');
    this._set('page.pageInfo.pageDetail3', (pageDetail && pageDetail[2]) || '');
    this._set(
      'page.pageInfo.destinationURL',
      `${document.location.origin}${document.location.pathname}`
    );
    this._set('page.category.primaryCategory', capitalize(primaryCategory || ''));
    this._set('page.category.siteType', siteType);
    subSection && this._set('page.category.subSection', subSection);
    subSubSection && this._set('page.category.subSubSection', `${subSection}:${subSubSection}`);
    this._set(
      'page.pageInfo.pageName',
      isCmsPage ? getCmsPageName(mergedProps) : getPageName(mergedProps)
    );

    // New data layer
    this._set('global.page.languageCode', language);
    this._set('global.page.brandCode', brandCode);
    this._set('global.page.siteName', brandName);
    this._set('global.page.pageTitle', document.title);
    this._set('global.page.version', `${getAppVersion()}`);

    pageType && this._set('global.page.pageType', pageType);
    this._set('global.page.pageDetail1', (pageDetail && pageDetail[0]) || '');
    this._set('global.page.pageDetail2', (pageDetail && pageDetail[1]) || '');
    this._set('global.page.pageDetail3', (pageDetail && pageDetail[2]) || '');
    this._set('global.page.primaryCategory', capitalize(primaryCategory || ''));
    this._set('global.page.siteType', siteType);
    this._set('global.page.loginStatus', loginStatus);
  }

  public setGuestPageData(
    props: {
      profileID?: string;
      pointsBalance?: string | number;
      rewardsTier?: string;
      attributes?: object;
    }[]
  ) {
    const { profileID, pointsBalance, rewardsTier, attributes } = props as unknown as Record<
      string,
      string
    >;

    this._set('user[0].profile[0].profileInfo.profileID', profileID);
    this._set('user[0].profile[0].profileInfo.pointsBalance', pointsBalance);
    this._set('user[0].profile[0].profileInfo.rewardsTier', rewardsTier);
    this._set('user[0].profile[0].attributes', attributes || {});

    // New data layer
    this._set('global.user.profileID', profileID);
    this._set('global.user.rewardsTier', rewardsTier);
    this._set('global.user.userType', attributes['goUserType'] || '');
  }

  public setPageInfo(key: string, value: string[] | string) {
    this._set(`page.pageInfo.${key}`, Array.isArray(value) ? value.join(',') : value);
    this._set(`global.page.${key}`, Array.isArray(value) ? value.join(',') : value);
  }

  public setPageName(
    props: {
      language?: string;
      brandName?: string;
      pageCategory?: string;
      primaryCategory?: string;
      pageName?: string;
      subSection?: string;
    }[]
  ) {
    this._set('page.pageInfo.pageName', getPageName(props as unknown as Record<string, string>));
  }

  setProperty(key: string, value: string) {
    this._set(key, Array.isArray(value) ? value.join(',') : value);
  }

  setPageDetails(details: { pageDetail: string; detailIdx?: number }[]) {
    const { pageDetail, detailIdx = 1 } = details as unknown as Record<string, number>;
    this._set(`page.pageInfo.pageDetail${detailIdx}`, pageDetail);
  }

  setEventAction(eventDetails: EventAction | EventAction[]) {
    const { eventAction, eventIdx = 0 } = eventDetails as unknown as Record<string, number>;
    this._set(`event[${eventIdx}].eventInfo.eventAction`, eventAction);
  }

  setFormErrors(formErrors: { errors: string[] | string; eventIdx?: number }[]) {
    const { errors, eventIdx = 0 } = formErrors as unknown as Record<string, number>;
    this.setEventAction({ eventAction: 'error', eventIdx });
    this._set('page.pageInfo.formError', Array.isArray(errors) ? errors.join(', ') : errors);
  }
  //used in personal-information page
  clearMetrics() {
    this._set('event', []);
    this._set('action', {});
    this.setPageInfo('pageDetail3', '');
    this.setPageInfo('formError', '');
    this.setProperty('page.attributes.alertTextMessage', '');
    this.setProperty('page.attributes.alertCode', '');
  }

  setFormErrorMessages(messages: string | string[]) {
    this._set(
      'page.pageInfo.formErrorMessage',
      Array.isArray(messages) ? messages.join(',') : messages
    );
  }

  trackEvent(eventName: string, params: unknown = null, resetEvents = false) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._track(eventName, params);
    if (resetEvents) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._resetEvents();
    }
  }

  trackGridItemClick(
    event: { label: string; gridPosition?: string } | { label: string; gridPosition?: string }[]
  ) {
    const eventAction = `${formatLabel(!Array.isArray(event) ? event.label : '')}initiated`;
    this.setEventAction([{ eventAction }]);
    this.trackPageDetails();
    this.trackEvent(eventAction);
  }

  public trackLoginError({ type, source }: { type: string; source: string }) {
    switch (type) {
      case 'account_locked':
        this._set(
          'page.attributes.alertTextMessage',
          'Your login failed too many times. Your account is locked, but it’s simple to get back in.'
        );
        this._set('page.attributes.alertCode', 'account_locked');
        break;
      case 'invalid_grant':
        this._set(
          'page.attributes.alertTextMessage',
          'Your login didn’t match our records. Please try again. Be careful: too many attempts will lock your account.'
        );
        this._set('page.attributes.alertCode', 'invalid_grant');
        break;
      case 'invalid_entry':
        this._set(
          'page.attributes.alertTextMessage',
          'Forgetting something? We need your username and password to login.'
        );
        this._set('page.attributes.alertCode', 'invalid_entry');
        break;
      case 'invalid_recaptcha':
        this._set(
          'page.attributes.alertTextMessage',
          "Please confirm you're not a robot to continue."
        );
        this._set('page.attributes.alertCode', 'invalid_recaptcha');
        break;
    }
    void this.track(source, null, true);
  }

  trackPageView(eventName?: string) {
    void this.track(eventName || 'myAccountPageView', null);
  }

  trackPageDetails() {
    this.trackEvent('pageDetail', null, false);
  }

  trackFormErrors() {
    this.trackEvent('formErrorEvent', null, false);
  }

  setErrorPageData(
    props: { errorCode: number | null; errorName: string | null; errorMessage: string | null }[]
  ) {
    const mergedProps = { ...defaultPageData, ...props };
    const { brandCode } = mergedProps;
    const { errorCode, errorMessage, errorName = '' } = props as unknown as Record<string, string>;

    this._set('event.eventInfo.eventAction', 'error');
    this._set('page.category.primaryCategory', 'error');
    this._set('page.category.subSection', `error:${errorName}`);
    this._set('page.pageInfo.error', errorCode);
    this._set('page.pageInfo.pageName', `${brandCode}:error:${errorName}`);
    this._set('page.pageInfo.pageDetail1', errorCode);
    this._set('page.category.primaryCategory', 'Error');
    errorMessage && this._set('page.category.subSubSection', `error:${errorName}:${errorMessage}`);
    errorMessage && this._set('page.pageInfo.pageType', errorMessage);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._track('errorPageView', null);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._track('errorEvent', null);
  }

  unsetProperty(path: string) {
    this._unset(path);
  }

  reset() {
    this.setDefaultPageData([{}]);
  }
}
