import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const REACT_QUERY_CONFIG = Object.freeze({
  appId: publicRuntimeConfig.DX_AUTH_API_CUSTOMER_APP_ID,
  appName: publicRuntimeConfig.APP_NAME,
  gqlEndpoints: {
    browser: publicRuntimeConfig.DX_GRAPHQL_CLIENT_URI,
    server: serverRuntimeConfig.DX_GRAPHQL_SERVER_URI,
  },
  authEndpoints: {
    browser: publicRuntimeConfig.DX_AUTH_API_CLIENT_URI,
    server: serverRuntimeConfig.DX_AUTH_API_SERVER_URI,
  },
});
