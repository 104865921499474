import getConfig from 'next/config';
import { UriBuilder } from '@dx-ui/framework-uri-builder';
import { useLanguage } from '../hooks/useLanguage';

const {
  publicRuntimeConfig: { REACT_APP_BRAND_HOST },
} = getConfig();

export function useStaticUrls() {
  const host = REACT_APP_BRAND_HOST;
  const language = useLanguage();
  const secure = true;
  const ohwPortfolioUrlBuilder = new UriBuilder({ host, language, secure });
  const ohwHonorsUrlBuilder = new UriBuilder({ host, language, brand: 'hilton-honors', secure });

  const attendeeWebsiteUrl = ohwHonorsUrlBuilder.build('/events/attendee-website/');
  const honorsForgotInfoUrl = ohwHonorsUrlBuilder.build('/login/forgot-information/');
  const personalDataRequestsUrl = ohwHonorsUrlBuilder.build('/personal-data-requests/');
  const privacyStatementUrl = ohwPortfolioUrlBuilder.build('/p/global-privacy-statement/');

  return {
    attendeeWebsiteUrl,
    honorsForgotInfoUrl,
    personalDataRequestsUrl,
    privacyStatementUrl,
  };
}
